import { PageHeader } from "UIPalette/PageHeader/PageHeader";
import { useParams } from "react-router-dom";
import { useGetFourWeekPlanFunctionRequest } from "shared/request/myHealthyAdvantageApi";
import { useTranslation } from "react-i18next";
import { DailyProgress } from "./components/DailyProgress";
import { TrackerCard } from "UIPalette/TrackerCard/TrackerCard";
import { LearningMaterials } from "UIPalette/LearningMaterials/LearningMaterials";
import { CurvedTopBanner } from "UIPalette/CurvedTopBanner/CurvedTopBanner";
import { TwoColumnLayout } from "UIPalette/Layout/TwoColumnLayout";
import { ViewStateWrapper } from "UIPalette/ViewStateWrapper/ViewStateWrapper";
import { NotFound } from "../../../../Views/NotFound";
import { datocmsUUIDRegex } from "shared/utils/regex";

export const ViewFourWeekPlan = () => {
  const { fourWeekPlanId } = useParams();
  const isValidFourWeekPlanId = fourWeekPlanId !== undefined && datocmsUUIDRegex.test(fourWeekPlanId);
  const { t } = useTranslation();
  const {
    data: fourWeekPlan,
    isLoading,
    error,
    mutate,
  } = useGetFourWeekPlanFunctionRequest(
    { id: fourWeekPlanId },
    { revalidateOnMountIfStale: false, showErrorIfStale: false, shouldFetch: isValidFourWeekPlanId }
  );

  if (!isValidFourWeekPlanId) {
    return <NotFound />;
  }

  return (
    <>
      <CurvedTopBanner>
        <PageHeader title={fourWeekPlan?.name!} backRoute={`/resources/4-week-plans/`} />
      </CurvedTopBanner>
      <ViewStateWrapper loading={isLoading} error={!!error} errorMessage={t("fourWeekPlans.singleError")} onRetry={mutate}>
        <TwoColumnLayout>
          <TwoColumnLayout.Left>
            <p>{fourWeekPlan?.description}</p>
            <DailyProgress fourWeekPlanId={fourWeekPlanId!} />
            {fourWeekPlan?.trackers?.map((tracker) => {
              return <TrackerCard key={tracker.id} tracker={tracker} />;
            })}
          </TwoColumnLayout.Left>
          {fourWeekPlan?.categories && fourWeekPlan?.categories.length > 0 && (
            <TwoColumnLayout.Right>
              <h2 className={"p-0"}>{t("fourWeekPlans.usefulArticles")}</h2>
              <LearningMaterials
                categoryIds={fourWeekPlan?.categories.map((category) => category.id)!}
                errorMessage={t("fourWeekPlans.articlesErrorButton")}
                ariaLabel={t("fourWeekPlans.articles")}
              />
            </TwoColumnLayout.Right>
          )}
        </TwoColumnLayout>
      </ViewStateWrapper>
    </>
  );
};
