import { useListFourWeekPlansFunctionRequest } from "shared/request/myHealthyAdvantageApi";
import { useTranslation } from "react-i18next";
import { FourWeekPlanList } from "UIPalette/FourWeekPlanList/FourWeekPlanList";
import { PageHeader } from "UIPalette/PageHeader/PageHeader";
import classNames from "classnames";
import { CurvedTopBanner } from "UIPalette/CurvedTopBanner/CurvedTopBanner";
import { ViewStateWrapper } from "UIPalette/ViewStateWrapper/ViewStateWrapper";

export const FourWeekPlans = () => {
  const { t } = useTranslation();
  const { data, isLoading, error, mutate } = useListFourWeekPlansFunctionRequest({});

  const hasActive4WeekPlans = data && data.active.length > 0;
  const hasAvailable4WeekPlans = data && data.available.length > 0;

  return (
    <>
      <CurvedTopBanner>
        <PageHeader title={t("fourWeekPlans.title")} backRoute="/resources" />
      </CurvedTopBanner>
      <ViewStateWrapper loading={isLoading} error={!!error} errorMessage={t("fourWeekPlans.listError")} onRetry={mutate} isFullScreenView={true}>
        <p className="col-start-1 sm:col-span-2 pb-3">{t("fourWeekPlans.intro")}</p>
        {hasActive4WeekPlans && (
          <div className="col-start-1 mb-6">
            <FourWeekPlanList title={t("fourWeekPlans.activePlansTitle")} plans={data.active} />
          </div>
        )}
        {hasAvailable4WeekPlans && (
          <div className={classNames({ "col-start-1": !hasActive4WeekPlans })}>
            <FourWeekPlanList title={t("fourWeekPlans.availablePlansTitle")} plans={data.available} />
          </div>
        )}
      </ViewStateWrapper>
    </>
  );
};
