import { StepsInput } from "./components/StepsInput/StepsInput";
import { StepsTrackerChart } from "./components/StepsTrackerChart";
import { StepsTrackerProgressCard } from "./components/Progress/StepsTrackerProgressCard";
import { Tracker } from "../components/Tracker";
import { TrackerType } from "core/state/Trackers/TrackerType";
import { t } from "i18next";
import { useMobilePlatform } from "core/hooks/useMobilePlatform";

export const StepsTracker = () => {
  const { isMobileApp } = useMobilePlatform();

  return (
    <Tracker trackerType={TrackerType.Steps} heading={t("healthHub.trackers.steps.title")} isSyncable={true}>
      <StepsTrackerProgressCard />
      <StepsTrackerChart />
      {!isMobileApp && <StepsInput />}
    </Tracker>
  );
};
