import React, { ReactElement } from "react";
import { ForwardedRef } from "react";
import { InputProps, Input } from "./Input";
import { ValidatableWrapper, ValidatableWrapperProps } from "../ValidatableWrapper";

export const ValidateableInput = React.forwardRef(
  (
    { validationMessage, validationState, ...props }: ValidatableWrapperProps & Omit<InputProps, "validationState">,
    _ref?: ForwardedRef<HTMLInputElement>
  ): ReactElement => {
    return (
      <ValidatableWrapper validationMessage={validationMessage} validationState={validationState}>
        <Input {...props} validationState={validationState} ref={_ref} />
      </ValidatableWrapper>
    );
  }
);
