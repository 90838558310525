import { ArticleCategoryList } from "./components/ArticleCategoryList/ArticleCategoryList";
import { ReactComponent as ArticleIcon } from "shared/assets/Icons/article.svg";
import { ReactComponent as NewsletterIcon } from "shared/assets/Icons/newsletter.svg";
import { ReactComponent as PodcastIcon } from "shared/assets/Icons/podcast.svg";
import { TwoColumnLayout } from "UIPalette/Layout/TwoColumnLayout";
import { ReactComponent as VideoIcon } from "shared/assets/Icons/video.svg";
import { WellKnownLearningMaterialCategories } from "UIPalette/LearningMaterials/wellKnownLearningMaterials";
import { WellbeingResourceTiles } from "./components/WellbeingResourceTiles/WellbeingResourceTiles";
import { WellbeingToolTiles } from "./components/WellbeingToolTiles/WellbeingToolTiles";
import { ukLocale } from "shared/core/locale";
import { useTranslation } from "react-i18next";
import { useUserContext } from "core/state/UserContext";

export const ResourcesHome = () => {
  const { t } = useTranslation();
  const { user } = useUserContext();
  return (
    <div className="mt-6">
      <TwoColumnLayout>
        <TwoColumnLayout.Left>
          <div>
            <h2>{t("resources.heading")}</h2>
            <p>{t("resources.headingParagraph")}</p>
          </div>

          <WellbeingToolTiles />
          <div>
            <h2>{t("resources.subHeading")}</h2>
            <p>{t("resources.subHeadingParagraph")}</p>
          </div>

          <WellbeingResourceTiles />
          {user?.localeId === ukLocale && (
            <ArticleCategoryList
              heading={t("resources.podcastsArticlesHeading")}
              description={t("resources.podcastsArticlesDescription")}
              category={WellKnownLearningMaterialCategories.podcasts}
              icon={<PodcastIcon width={24} height={24} />}
              iconBackgroundColour="bg-xl-orange"
              iconForegroundColour="text-orange"
              ariaLabel={t("resources.podcastsArticlesLabel")}
            />
          )}
          <ArticleCategoryList
            heading={t("resources.audioAndVideoArticlesHeading")}
            description={t("resources.audioAndVideoArticlesDescription")}
            category={WellKnownLearningMaterialCategories.audioAndVideo}
            icon={<VideoIcon width={24} height={24} />}
            iconBackgroundColour="bg-sky"
            iconForegroundColour="text-primary-700"
            ariaLabel={t("resources.audioAndVideoArticlesLabel")}
          />
        </TwoColumnLayout.Left>
        <TwoColumnLayout.Right>
          <ArticleCategoryList
            heading={t("resources.articleLibraryHeading")}
            description={t("resources.articleLibraryDescription")}
            category={WellKnownLearningMaterialCategories.articles}
            icon={<ArticleIcon width={24} height={24} />}
            iconBackgroundColour="bg-sky"
            iconForegroundColour="text-primary-700"
            ariaLabel={t("resources.articlesLabel")}
          />
          <ArticleCategoryList
            heading={t("resources.newsletterArticlesHeading")}
            description={t("resources.newsletterArticlesDescription")}
            category={WellKnownLearningMaterialCategories.newsletters}
            icon={<NewsletterIcon width={24} height={24} />}
            iconBackgroundColour="bg-xl-orange"
            iconForegroundColour="text-orange"
            ariaLabel={t("resources.newsletterArticlesLabel")}
          />
          {user?.localeId === ukLocale && (
            <ArticleCategoryList
              heading={t("resources.brightTVArticlesHeading")}
              description={t("resources.brightTVArticlesDescription")}
              category={WellKnownLearningMaterialCategories.brightTV}
              icon={<VideoIcon width={24} height={24} />}
              iconBackgroundColour="bg-xl-lilac"
              iconForegroundColour="text-d-violet"
              ariaLabel={t("resources.brightTVArticlesLabel")}
            />
          )}
        </TwoColumnLayout.Right>
      </TwoColumnLayout>
    </div>
  );
};
