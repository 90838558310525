import { ReactComponent as CallUsIcon } from "shared/assets/Icons/phone.svg";
import { ClickableRoundedCard } from "../ContactUs/Components/ContactCard/ClickableRoundedCard";
import { MoodTrackerCard } from "UIPalette/MoodTrackerCard/MoodTrackerCard";
import { PerksAndDiscountsCard } from "./components/PerksAndDiscountsCard/PerksAndDiscountsCard";
import { PersonalisedNewsFeed } from "./components/PersonalisedNewsFeed/PersonalisedNewsFeed";
import { TodaysMoodCard } from "./components/TodaysMoodCard/TodaysMoodCard";
import { TrackerType } from "core/state/Trackers/TrackerType";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import { useTrackerContext } from "core/state/Trackers/TrackerContext";
import WisdomModules from "core/Modules";
import { ProtectedModule } from "core/auth/ProtectedModule";
import { RoundedCard } from "shared/UI/RoundedCard/RoundedCard";
import { WaterTrackerProgressTile } from "../HealthHub/components/WaterTrackerProgressTile/WaterTrackerProgressTile";
import { StepsTrackerProgressTile } from "../HealthHub/components/StepsTrackerProgressTile/StepsTrackerProgressTile";
import { SleepTrackerProgressTile } from "../HealthHub/components/SleepTrackerProgressTile/SleepTrackerProgressTile";
import { GeneralActivityTrackerProgressTile } from "../HealthHub/components/GeneralActivityTrackerProgressTile/GeneralActivityTrackerProgressTile";
import { useUserContext } from "core/state/UserContext";
import { useGreeting } from "../../hooks/useGreeting";
import ClientLogoImage from "shared/UI/ClientLogo/ClientLogoImage";
import { LeaderboardsSingleCard } from "../HealthHub/components/LeaderboardsSingleCard/LeaderboardsSingleCard";
import { TwoColumnLayout } from "UIPalette/Layout/TwoColumnLayout";

function ContactUsCard(props: { handleClick: () => void }) {
  return (
    <ClickableRoundedCard
      title={t("home.contactUs.title")}
      icon={<CallUsIcon />}
      description={t("home.contactUs.description")}
      handleClick={props.handleClick}
      showChevron
    />
  );
}

function MoodTrackerModule(props: { moodSet: boolean }) {
  return <>{props.moodSet ? <TodaysMoodCard /> : <MoodTrackerCard />}</>;
}

function TrackersModule() {
  return (
    <RoundedCard>
      <h4>{t("home.trackers.title")}</h4>
      <div className={"flex flex-row justify-evenly h-max-[5rem]"}>
        <WaterTrackerProgressTile inCard={false} size={"md"} />
        <StepsTrackerProgressTile inCard={false} size={"md"} />
        <SleepTrackerProgressTile inCard={false} size={"md"} />
        <GeneralActivityTrackerProgressTile inCard={false} size={"md"} />
      </div>
    </RoundedCard>
  );
}

export const Home = () => {
  const { getCurrentValue } = useTrackerContext();
  const { user } = useUserContext();
  const moodSet = getCurrentValue(TrackerType.Mood) > 0;
  const navigate = useNavigate();
  const greeting = useGreeting();

  const client = user?.client;

  return (
    <div className="mt-6">
      <div>
        {client && client.logoUrl && (
          <div className={"flex justify-end mb-2"}>
            <div>
              <ClientLogoImage
                className="max-h-10 md:max-w-[11rem] max-w-[7rem]  h-full object-contain"
                clientCode={client?.code}
                imageFilename={client.logoUrl}
                alt={t("home.clientLogoAltText")}
              />
            </div>
          </div>
        )}
        <h2>{t("home.greeting", { timeOfDay: greeting, firstName: user?.forenames })}</h2>
      </div>
      <TwoColumnLayout>
        <TwoColumnLayout.Left>
          <ProtectedModule wisdomModule={WisdomModules.trackers} moduleComponent={<TrackersModule />} />
          <div className="md:hidden">
            <ProtectedModule wisdomModule={WisdomModules.moodTracker} moduleComponent={<MoodTrackerModule moodSet={moodSet} />} />
          </div>
          <PersonalisedNewsFeed />
        </TwoColumnLayout.Left>
        <TwoColumnLayout.Right>
          <div className="hidden md:block">
            <ProtectedModule wisdomModule={WisdomModules.moodTracker} moduleComponent={<MoodTrackerModule moodSet={moodSet} />} />
          </div>
          <ProtectedModule wisdomModule={WisdomModules.perksAndDiscounts} moduleComponent={<PerksAndDiscountsCard />} />
          <ProtectedModule wisdomModule={WisdomModules.leaderBoards} moduleComponent={<LeaderboardsSingleCard />} />
          <ContactUsCard handleClick={() => navigate("/contact-us")} />
        </TwoColumnLayout.Right>
      </TwoColumnLayout>
    </div>
  );
};
