import classNames from "classnames";
import { ReactElement } from "react";
import "shared/extensions/string";

export type IconProps = {
  caption: string;
  icon: ReactElement;
  fgColour: string;
  bgColour: string;
  hasDropShadow: boolean;
};

export const Icon = ({ caption, icon, fgColour, bgColour, hasDropShadow }: IconProps) => {
  return (
    <div className="flex flex-col items-center w-16">
      <div
        className={classNames("inline-flex", "items-center", "justify-center", "w-12", "h-12", "rounded-full", fgColour, bgColour, {
          "drop-shadow-md": hasDropShadow,
        })}
      >
        {icon}
      </div>
      {caption.isNullOrEmpty() ? null : <p className="body-2 text-center pt-3">{caption}</p>}
    </div>
  );
};

Icon.defaultProps = { caption: "", fgColour: "text-primary-700", bgColour: "bg-white", hasDropShadow: true };
