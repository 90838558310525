import { PropsWithChildren, createContext, useContext, useState } from "react";
import { useUserContext } from "core/state/UserContext";
import { ClientCodeState } from "shared/core/state/clientCodeState";
import { useTranslation } from "react-i18next";
import { FullScreenModal } from "UIPalette/FullScreenModal/FullScreenModal";
import { useLiveChatApi } from "../hooks/useLiveChatApi";
import { ILiveAgentApi } from "../hooks/liveagent";

export type LiveChatContextType = {
  isChatAvailable: boolean;
  startChat: VoidFunction;
};

export const LiveChatContext = createContext<LiveChatContextType>({} as LiveChatContextType);

export const useLiveChat = () => useContext(LiveChatContext);

export const LiveChatProvider = ({ children }: PropsWithChildren) => {
  const { user } = useUserContext();
  const { t } = useTranslation();

  const initialiseChat = (api: ILiveAgentApi) => {
    if (user) {
      api.addCustomDetail("Name", `${user.forenames} ${user.surname}`, true);
      if (user.phoneNumber) {
        api.addCustomDetail("Phone Number", user.phoneNumber, true);
      }
      const clientCode = ClientCodeState.get();
      if (clientCode) {
        api.addCustomDetail("Client Code", clientCode, true);
      }
    }
  };

  const { isChatAvailable, chatButtonId, api } = useLiveChatApi(initialiseChat);
  const [showChatWindow, setShowChatWindow] = useState(false);

  const providedValue: LiveChatContextType = {
    isChatAvailable: isChatAvailable,
    startChat: () => {
      setShowChatWindow(true);
      // Hack/delay to wait until DOM ready before starting chat via API (useEffect doesn't work)
      setTimeout(() => {
        api?.startChatWithWindow(chatButtonId, "chat-window");
      }, 1);
    },
  };

  return (
    <>
      <LiveChatContext.Provider value={providedValue}>{children}</LiveChatContext.Provider>
      {showChatWindow && (
        <FullScreenModal title={t("contactUs.liveChat.title")} onClose={() => setShowChatWindow(false)}>
          <iframe title={t("contactUs.liveChat.title")} name="chat-window" className="h-5/6 w-full" onLoad={() => console.log("frame loaded")} />
        </FullScreenModal>
      )}
    </>
  );
};
