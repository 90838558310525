import "shared/extensions/date";

import { Controller, useForm } from "react-hook-form";
import { UpdateUserCommand, useUpdateUserFunctionRequest } from "shared/request/myHealthyAdvantageApi";

import { AppRoutes } from "core/AppRoutes";
import { CurvedTopBanner } from "UIPalette/CurvedTopBanner/CurvedTopBanner";
import { DivisionSelect } from "UIPalette/Components/DivisionSelect/DivisionSelect";
import ErrorAlert from "shared/UI/Alerts/ErrorAlert";
import { GenderSelect } from "UIPalette/Components/GenderSelect/GenderSelect";
import { NoValueSelected } from "shared/constants/SelectConstants";
import { PageHeader } from "UIPalette/PageHeader/PageHeader";
import { ResponsiveBreakpoints } from "shared/UI/ResponsiveBreakpoints";
import StylableButton from "shared/UI/Buttons/StylableButton";
import { ValidatableDatePicker } from "shared/UI/Inputs/DateInput/ValidatableDatePicker";
import { ValidatableWrapper } from "shared/UI/Inputs/ValidatableWrapper";
import { ValidateableInput } from "shared/UI/Inputs/Input/ValidateableInput";
import { phoneNumberRegex } from "shared/utils/regex";
import { toastNotify } from "shared/UI/Toaster/Toaster";
import { useMatchMedia } from "shared/UI/Hooks/useMatchMedia";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useUserContext } from "core/state/UserContext";

type Inputs = {
  forenames: string;
  surname: string;
  dateOfBirth: Date;
  gender: string;
  phoneNumber: string;
  divisionId: string | undefined;
};
export const PersonalDetails = () => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState(false);
  const { trigger } = useUpdateUserFunctionRequest({});
  const { user, saveUser } = useUserContext();
  const largerDisplay = useMatchMedia(ResponsiveBreakpoints.Desktop, false);

  const {
    control,
    handleSubmit,
    register,
    reset,
    getValues,
    formState: { errors, defaultValues, isDirty },
  } = useForm<Inputs>({
    defaultValues: {
      forenames: user?.forenames ?? "",
      surname: user?.surname ?? "",
      dateOfBirth: user?.dateOfBirth ? Date.fromDateOnly(user.dateOfBirth) : undefined,
      gender: user?.gender ?? "",
      phoneNumber: user?.phoneNumber ?? "",
      divisionId: user?.division?.id ?? NoValueSelected,
    },
  });

  const onSubmit = async (inputs: Inputs) => {
    setErrorMessage(false);
    const fullData: UpdateUserCommand = {
      dateOfBirth: inputs.dateOfBirth.toDateOnly(),
      gender: inputs.gender,
      phoneNumber: inputs.phoneNumber,
      forenames: inputs?.forenames,
      surname: inputs?.surname,
      divisionId: inputs.divisionId === NoValueSelected ? undefined : inputs.divisionId,
    };

    try {
      const response = await trigger({ body: fullData });
      saveUser(response!.data);
      toastNotify(t("personalDetails.requestSuccessMessage"), "success");
      reset(getValues());
    } catch {
      setErrorMessage(true);
    }
  };

  return (
    <>
      <CurvedTopBanner>
        <PageHeader title={t("personalDetails.heading")} backRoute={largerDisplay ? AppRoutes.home : AppRoutes.account} />
      </CurvedTopBanner>
      <form onSubmit={handleSubmit(onSubmit)} className={"w-full max-w-[600px] mx-auto"}>
        <ValidateableInput
          type="text"
          label={t("personalDetails.forenames")}
          defaultValue={defaultValues?.forenames}
          validationState={errors.forenames ? "error" : undefined}
          validationMessage={t("personalDetails.forenamesErrorMessage")}
          {...register("forenames", { required: true })}
        />

        <ValidateableInput
          type="text"
          label={t("personalDetails.surname")}
          defaultValue={defaultValues?.surname}
          validationState={errors.surname ? "error" : undefined}
          validationMessage={t("personalDetails.surnameErrorMessage")}
          {...register("surname", { required: true })}
        />

        <Controller
          name={"dateOfBirth"}
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <ValidatableDatePicker
              maxDate={new Date()}
              label={t("personalDetails.dateOfBirth")}
              date={value}
              validationState={errors.dateOfBirth ? "error" : undefined}
              validationMessage={t("personalDetails.dateOfBirthErrorMessage")}
              setDate={onChange}
            />
          )}
        />

        <Controller
          name={"gender"}
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <ValidatableWrapper validationState={errors.gender ? "error" : undefined} validationMessage={t("personalDetails.genderErrorMessage")}>
              <GenderSelect onChange={onChange} label={t("personalDetails.gender")} value={value} />
            </ValidatableWrapper>
          )}
        />

        <ValidateableInput
          validationMessage={t("personalDetails.phoneNumberErrorMessage")}
          validationState={errors.phoneNumber ? "error" : undefined}
          type="tel"
          defaultValue={defaultValues?.phoneNumber}
          label={t("personalDetails.phoneNumber")}
          {...register("phoneNumber", { pattern: phoneNumberRegex })}
        />

        <Controller
          name={"divisionId"}
          control={control}
          render={({ field: { onChange, value } }) => <DivisionSelect onChange={onChange} label={t("personalDetails.division")} value={value} />}
        />

        <StylableButton
          className="bright-button mt-4"
          fullWidth
          disabled={!isDirty}
          color="primary"
          type="submit"
          text={t("personalDetails.updatePersonalDetails")}
          aria-label={t("personalDetails.updatePersonalDetails")}
        />
      </form>

      {errorMessage && (
        <div className="mt-2">
          <ErrorAlert content={t("personalDetails.requestErrorMessage")} />
        </div>
      )}
    </>
  );
};
