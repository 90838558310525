import { PropsWithChildren } from "react";

export type HideableProps = {
  hidden: boolean;
  className?: string;
};

export const Hideable = ({
  hidden,
  children,
}: HideableProps & PropsWithChildren) => {
  if (hidden) return <></>;

  return <>{children}</>;
};
