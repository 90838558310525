import { toastNotify } from "shared/UI/Toaster/Toaster";
import axios from "axios";
import SafeSpaceImage from "shared/assets/SafeSpacesImage.png";
import React from "react";
import { t } from "i18next";

interface SafeSpacesProps {
  className?: string;
}

const homeIsAliveUrl = "https://safespaces.azurewebsites.net/Home/IsAlive";
const safeSpacesPageUrl = "https://safespaces.azurewebsites.net/";

export function SafeSpaces({ className }: SafeSpacesProps) {
  function handleClick() {
    isSafeSpaceAvailable()
      .then(() => launchSafeSpace())
      .catch(() => {
        toastNotify(t("safeSpaces.errorToast"), "error");
      });
  }

  function isSafeSpaceAvailable() {
    return axios.get(homeIsAliveUrl);
  }

  const launchSafeSpace = () => {
    const targetElement = document.body;

    axios.get(safeSpacesPageUrl).then((response) => {
      hostWidgetInIframe(response.data, targetElement);
    });
  };

  const hostWidgetInIframe = (resultHtml: string, targetEl: HTMLElement) => {
    const iframe = document.createElement("iframe");
    iframe.setAttribute("id", "safe-space-iframe");
    iframe.setAttribute("title", "safe-space-widget-iframe");

    targetEl.appendChild(iframe);
    targetEl.style.overflow = "hidden";
    iframe.setAttribute("style", "height:100%;width:100%;position:fixed;top:0;bottom:0;background-color: rgba(0, 0, 0, 0.26);z-index:9999;");

    iframe.contentWindow?.document.open();
    iframe.contentWindow?.document.write(resultHtml);
    iframe.contentWindow?.document.close();
    iframe.focus();
  };

  return (
    <div className={"flex items-center justify-center"}>
      <button onClick={handleClick} className={`relative overflow-hidden w-[7.5rem] ${className} inline p-4`}>
        <img src={SafeSpaceImage} alt={"safe space"} className="w-full object-contain max-w-full max-h-full" />
      </button>
    </div>
  );
}
