import { FallbackProps } from "react-error-boundary";
import { appInsights } from "core/monitoring/AppInsights";
import Alert from "shared/UI/Alerts/Alert";
import { useNavigate } from "react-router-dom";
import StylableButton from "shared/UI/Buttons/StylableButton";
import { useTranslation } from "react-i18next";

const devMode = !process.env.NODE_ENV || process.env.NODE_ENV === "development";

export function ErrorFallback({ error, resetErrorBoundary }: FallbackProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className={`flex flex-col items-center justify-center`}>
      <Alert icon="error" size="base" title={t("generalErrors.title")} type="error">
        <p className="message">{error.message}</p>
      </Alert>
      <StylableButton
        color="primary"
        onClick={() => {
          resetErrorBoundary();
          navigate("/");
        }}
        text={"Go to Home"}
      />
    </div>
  );
}

export function handleError(error: Error, info: { componentStack: string }) {
  if (!devMode) {
    const exception = { exception: error, severityLevel: 3 };
    appInsights.trackException(exception);
  }
}
