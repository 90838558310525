import { t } from "i18next";
import { WisdomButtonCard } from "./Components/WisdomButtonCard";
import { UserState } from "core/state/userState";
import { useNavigate } from "react-router-dom";
import { useLiveChat } from "./Components/LiveChatProvider";
import { ReportingEventType } from "shared/constants/ReportingEventType";
import { useLogReportingEventFunctionRequest } from "shared/request/myHealthyAdvantageApi";
import { SafeSpaces } from "../Home/components/SafeSpaces/SafeSpaces";
import { ProtectedModule } from "core/auth/ProtectedModule";
import WisdomModules from "core/Modules";
import { ReactComponent as LiveChatIcon } from "shared/assets/Icons/contact-us.svg";
import { ReactComponent as LiveChatUnavailableIcon } from "shared/assets/Icons/contact-us-unavailable.svg";
import { ReactComponent as VideoCallIcon } from "shared/assets/Icons/video-call.svg";
import { ReactComponent as VideoCallUnavailableIcon } from "shared/assets/Icons/video-call-unavailable.svg";
import { ReactComponent as CallbackIcon } from "shared/assets/Icons/request-contact.svg";
import { ReactComponent as CallUsIcon } from "shared/assets/Icons/phone.svg";
import { useVideoCall } from "./hooks/useVideoCall";
import { ClickableRoundedCard } from "./Components/ContactCard/ClickableRoundedCard";
import { RoundedCard } from "shared/UI/RoundedCard/RoundedCard";
import { ContactCardWithButton } from "./Components/ContactCard/ContactCardWithButton";
import { ResourceTile } from "../Resources/views/ResourcesHome/components/ResourceTile";
import { dial } from "core/javascriptChannels";

export const ContactUs = () => {
  const user = UserState.get();
  const helplinePhoneNumber = user?.helplinePhoneNumber ?? "";
  const sanitisedPhoneNumber = helplinePhoneNumber.replace(/ /g, "");
  const navigate = useNavigate();
  const { trigger } = useLogReportingEventFunctionRequest();
  const { isChatAvailable, startChat } = useLiveChat();
  const { isVideoCallAvailable, startVideoCall } = useVideoCall();

  return (
    <div className="mt-6">
      <h2>{t("contactUs.heading")}</h2>
      <p className="mb-5 col-start-1">{t("contactUs.headingParagraph")}</p>
      <div className="flex flex-row md:hidden justify-around text-lg w-full h-fit gap-2 md:gap-4">
        <ResourceTile
          title={t("contactUs.liveChat.title")}
          ariaLabel={t("contactUs.liveChat.title")}
          ResourceIcon={isChatAvailable ? LiveChatIcon : LiveChatUnavailableIcon}
          onClick={() => trigger({ body: { reportingEventType: ReportingEventType.SupportRequestLiveChat } }).finally(startChat)}
          active={isChatAvailable}
        />
        <ProtectedModule
          wisdomModule={WisdomModules.videoChat}
          moduleComponent={
            <ResourceTile
              title={t("contactUs.videoCall.title")}
              ariaLabel={t("contactUs.videoCall.title")}
              ResourceIcon={isVideoCallAvailable ? VideoCallIcon : VideoCallUnavailableIcon}
              onClick={() => trigger({ body: { reportingEventType: ReportingEventType.SupportRequestVideoCall } }).finally(startVideoCall)}
              active={isVideoCallAvailable}
            />
          }
        />
      </div>
      <div className="flex flex-row md:hidden justify-around text-lg lg:text-xl w-full h-fit [&>*]:m-2">
        <ResourceTile
          title={t("contactUs.callback.title")}
          ariaLabel={t("contactUs.callback.title")}
          ResourceIcon={CallbackIcon}
          onClick={() =>
            trigger({ body: { reportingEventType: ReportingEventType.SupportRequestRequestCallback } }).finally(() =>
              navigate("/contact-us/callback")
            )
          }
        />
        <ResourceTile
          title={t("contactUs.callUs.title")}
          ariaLabel={t("contactUs.callUs.title")}
          ResourceIcon={CallUsIcon}
          onClick={() => trigger({ body: { reportingEventType: ReportingEventType.SupportRequestPhone } }).finally(() => dial(sanitisedPhoneNumber))}
        />
      </div>
      <div className="flex flex-row">
        <div className="hidden md:block w-1/2 [&>*]:m-4">
          <ClickableRoundedCard
            title={t("contactUs.liveChat.title")}
            icon={isChatAvailable ? <LiveChatIcon /> : <LiveChatUnavailableIcon />}
            description={isChatAvailable ? t("contactUs.liveChat.description") : t("contactUs.liveChat.descriptionOffline")}
            disabled={!isChatAvailable}
            showChevron={isChatAvailable}
            handleClick={() => trigger({ body: { reportingEventType: ReportingEventType.SupportRequestLiveChat } }).finally(() => startChat())}
          />
          <ProtectedModule
            wisdomModule={WisdomModules.videoChat}
            moduleComponent={
              <ClickableRoundedCard
                title={t("contactUs.videoCall.title")}
                icon={isVideoCallAvailable ? <VideoCallIcon /> : <VideoCallUnavailableIcon />}
                description={isVideoCallAvailable ? t("contactUs.videoCall.description") : t("contactUs.videoCall.descriptionOffline")}
                handleClick={() =>
                  trigger({ body: { reportingEventType: ReportingEventType.SupportRequestRequestCallback } }).finally(startVideoCall)
                }
                showChevron={isVideoCallAvailable}
                disabled={!isVideoCallAvailable}
              />
            }
          />
        </div>
        <div className="hidden md:block w-1/2 [&>*]:m-4">
          <RoundedCard>
            <h4 className="font-semibold text-black">{t("contactUs.callUs.description")}</h4>
            <div className="flex items-center">
              <CallUsIcon className={"w-12"} />
              <h3 className="ml-5">{helplinePhoneNumber}</h3>
            </div>
          </RoundedCard>
          <ContactCardWithButton
            buttonText={t("contactUs.callback.title")}
            buttonColour="primary"
            handleClick={() =>
              trigger({ body: { reportingEventType: ReportingEventType.SupportRequestRequestCallback } }).finally(() =>
                navigate("/contact-us/callback")
              )
            }
            icon={<CallbackIcon />}
            description={t("contactUs.callback.description")}
          />
        </div>
      </div>
      <div className={"flex flex-col w-full md:w-1/2 [&>*]:m-4"}>
        <WisdomButtonCard />
        <SafeSpaces />
      </div>
    </div>
  );
};
