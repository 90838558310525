import { useTranslation } from "react-i18next";
import { UserState } from "core/state/userState";
import { UpdateUserCommand, UserResponseModel, useUpdateUserFunctionRequest } from "shared/request/myHealthyAdvantageApi";
import { Controller, useForm } from "react-hook-form";
import ErrorAlert from "shared/UI/Alerts/ErrorAlert";
import StylableButton from "shared/UI/Buttons/StylableButton";
import { phoneNumberRegex } from "shared/utils/regex";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DestinationState } from "shared/core/state/destinationState";
import "shared/extensions/date";
import { GenderSelect } from "UIPalette/Components/GenderSelect/GenderSelect";
import { useUserContext } from "core/state/UserContext";
import { ValidateableInput } from "shared/UI/Inputs/Input/ValidateableInput";
import { ValidatableDatePicker } from "shared/UI/Inputs/DateInput/ValidatableDatePicker";
import { ValidatableWrapper } from "shared/UI/Inputs/ValidatableWrapper";
import { DivisionSelect } from "UIPalette/Components/DivisionSelect/DivisionSelect";
import { NoValueSelected } from "shared/constants/SelectConstants";

type Inputs = {
  firstName: string;
  lastName: string;
  dateOfBirth: Date;
  gender: string;
  phoneNumber: string;
  division: string;
};

export const AdditionalInfo = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState(false);
  const { trigger } = useUpdateUserFunctionRequest({});
  const storedUser = UserState.get();
  const navigateHook = useNavigate();
  const { saveUser } = useUserContext();

  const {
    handleSubmit,
    register,
    control,
    reset,
    getValues,
    formState: { errors, defaultValues, isDirty },
  } = useForm<Inputs>({
    defaultValues: {
      firstName: storedUser?.forenames ?? "",
      lastName: storedUser?.surname ?? "",
      dateOfBirth: Date.fromDateOnly(storedUser?.dateOfBirth),
      gender: storedUser?.gender ?? "",
      phoneNumber: storedUser?.phoneNumber ?? "",
      division: storedUser?.division?.id,
    },
  });

  const onSubmit = async (additionalInfo: Inputs) => {
    setErrorMessage(false);
    const fullData: UpdateUserCommand = {
      forenames: additionalInfo.firstName,
      surname: additionalInfo.lastName,
      dateOfBirth: additionalInfo.dateOfBirth.toDateOnly(),
      gender: additionalInfo.gender,
      phoneNumber: additionalInfo.phoneNumber,
      divisionId: additionalInfo.division === NoValueSelected ? undefined : additionalInfo.division,
    };

    try {
      const user = (await trigger({ body: fullData }))?.data!;
      saveUser(user);
      reset(getValues());
      navigate(user);
    } catch {
      setErrorMessage(true);
    }
  };

  const navigate = (user: UserResponseModel) => {
    const hasInterests = (user.interests.length ?? 0) !== 0;
    let destination = DestinationState.get();
    destination = destination !== pathname ? destination : "/";
    navigateHook(hasInterests ? destination : "/preferences");
  };

  return (
    <>
      <h2>{t("additionalInfo.heading")}</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ValidateableInput
          label={t("additionalInfo.firstName")}
          type="text"
          defaultValue={defaultValues?.firstName}
          validationState={errors.firstName ? "error" : undefined}
          validationMessage={t("additionalInfo.firstNameErrorMessage")}
          {...register("firstName", { required: true })}
        />

        <ValidateableInput
          type="text"
          label={t("additionalInfo.lastName")}
          defaultValue={defaultValues?.lastName}
          validationState={errors.lastName ? "error" : undefined}
          validationMessage={t("additionalInfo.lastNameErrorMessage")}
          {...register("lastName", { required: true })}
        />

        <Controller
          name={"dateOfBirth"}
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <ValidatableDatePicker
              maxDate={new Date()}
              label={t("additionalInfo.dateOfBirth")}
              date={value}
              validationState={errors.dateOfBirth ? "error" : undefined}
              validationMessage={t("additionalInfo.dateOfBirthErrorMessage")}
              setDate={onChange}
            />
          )}
        />

        <Controller
          name={"gender"}
          control={control}
          rules={{
            required: true,
          }}
          render={({ field: { onChange, value } }) => (
            <ValidatableWrapper validationMessage={t("additionalInfo.genderErrorMessage")} validationState={errors.gender ? "error" : undefined}>
              <GenderSelect onChange={onChange} label={t("additionalInfo.gender")} value={value} />
            </ValidatableWrapper>
          )}
        />

        <ValidateableInput
          validationMessage={t("additionalInfo.phoneNumberErrorMessage")}
          validationState={errors.phoneNumber ? "error" : undefined}
          type="tel"
          defaultValue={defaultValues?.phoneNumber}
          label={t("additionalInfo.phoneNumber")}
          {...register("phoneNumber", { pattern: phoneNumberRegex })}
        />

        <Controller
          name={"division"}
          control={control}
          render={({ field: { onChange, value } }) => <DivisionSelect onChange={onChange} label={t("additionalInfo.division")} value={value} />}
        />

        <StylableButton
          className="bright-button mt-4"
          fullWidth
          disabled={!isDirty}
          color="primary"
          type="submit"
          text={t("additionalInfo.completeAccount")}
          aria-label={t("additionalInfo.completeAccount")}
        />
      </form>

      {errorMessage && <ErrorAlert content={t("additionalInfo.requestErrorMessage")} />}
    </>
  );
};
