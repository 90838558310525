export const userLocale = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;

export const isHealthAssuredLocale = ["en-GB", "en-IE", "en-US", "en"].includes(userLocale);

export const ukLocale = "dac1f6b0-3ee2-4e07-8bcc-61dbc5060e7b";

export const auLocale = "e89b9cc2-cee4-4829-9831-433406e0b792";

export const localeCodeIds = {
  en: ukLocale,
  en_AU: auLocale,
};

export const LocaleIdToCodeMap = {
  [ukLocale]: "en",
  [auLocale]: "en_AU",
};

export const DomainLocaleCode: Record<string, "en" | "en_AU"> = {
  "wisdom.healthassured.org": "en",
  "app.wisdomwellbeing.com": "en_AU",
};
