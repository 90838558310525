import { StructuredText, StructuredTextDocument } from "react-datocms";
import { useTranslation } from "react-i18next";
import { Content } from "shared/request/myHealthyAdvantageApi";
import { ModalBody, ModalFooter } from "../../../../../UIPalette/Modal/Modal";
import StylableButton from "shared/UI/Buttons/StylableButton";

export type MiniHealthCheckQuestionnaireIntroProps = {
  intro: Content;
  onCancel: VoidFunction;
  onNext: VoidFunction;
};

export const MiniHealthCheckQuestionnaireIntro = ({ intro, onNext, onCancel }: MiniHealthCheckQuestionnaireIntroProps) => {
  const { t } = useTranslation();
  return (
    <>
      <ModalBody>
        <div className="h-80">
          <StructuredText data={intro as StructuredTextDocument} />
        </div>
      </ModalBody>
      <ModalFooter>
        <StylableButton text={t("navigation.cancelButton")} outlineButton color="primary" onClick={onCancel} />
        <StylableButton text={t("navigation.nextButton")} color="primary" onClick={onNext} />
      </ModalFooter>
    </>
  );
};
