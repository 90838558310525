import ErrorAlert from "shared/UI/Alerts/ErrorAlert";
import { Hideable } from "shared/UI/Hideable/Hideable";
import { Input } from "shared/UI/Inputs/Input/Input";
import { Modal } from "UIPalette/Modal/Modal";
import StylableButton from "shared/UI/Buttons/StylableButton";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export type EnterClientCodeProps = {
  handleClick: Function;
  error?: string;
  initialClientCode: string | undefined;
};

export const EnterClientCode = ({ handleClick, error, initialClientCode }: EnterClientCodeProps) => {
  const { t } = useTranslation();
  const [clientCode, setClientCode] = useState(initialClientCode ?? "");
  const [helpOpen, setHelpOpen] = useState(false);

  const handleChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    setClientCode(value);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleClick(clientCode);
    }
  };

  return (
    <>
      <h1 className="text-center">{t("signup.heading")}</h1>
      <Input
        label={t("signup.clientCodeLabel")}
        placeholder={t("signup.clientCodePlaceholder")}
        type="text"
        value={clientCode}
        onChange={handleChange}
        onKeyUp={handleKeyPress}
      />
      {error ? <ErrorAlert title={t("signup.confirmCodeErrorTitle")} content={error} /> : null}
      <StylableButton className="bright-button mt-2" fullWidth color="primary" text={t("signup.button")} onClick={() => handleClick(clientCode)} />
      <div className="text-center mt-5">
        <p>{t("signup.helpText")}</p>
        <button
          className="m-2 text-brand font-bold text-[14pt]"
          role="link"
          onClick={() => setHelpOpen(true)}
          aria-label={t("signup.helpButtonText")}
        >
          {t("signup.helpButtonText")}
        </button>
      </div>

      <Hideable hidden={!helpOpen}>
        <Modal close={() => setHelpOpen(false)}>
          <div className="p-3">
            <h2>{t("signup.helpModal.title")}</h2>
            <p>{t("signup.helpModal.body")}</p>
            <h2>{t("signup.helpModal.contactTitle")}</h2>
            <a
              className="w-full border-2 p-2 border-primary-700 rounded block text-primary-700 font-bold text-center no-underline"
              href={`tel:${process.env.REACT_APP_CLIENT_SERVICE_PHONE!}`}
            >
              {process.env.REACT_APP_CLIENT_SERVICE_PHONE!}
            </a>
            <p className="text-center my-2">{t("signup.helpModal.or")}</p>
            <a
              className="w-full border-2 p-2 border-primary-700 rounded block text-primary-700 font-bold text-center no-underline"
              href={`mailto:${process.env.REACT_APP_CLIENT_SERVICE_EMAIL!}`}
            >
              {process.env.REACT_APP_CLIENT_SERVICE_EMAIL!}
            </a>
          </div>
        </Modal>
      </Hideable>
    </>
  );
};
