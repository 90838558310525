import { SleepDurationInputModal } from "./components/SleepDurationInputModal";
import { SleepTrackerChart } from "./components/SleepTrackerChart";
import { SleepTrackerProgressCard } from "./components/Progress/SleepTrackerProgressCard";
import { TrackerType } from "core/state/Trackers/TrackerType";
import { useTranslation } from "react-i18next";
import { Tracker } from "../components/Tracker";

export const SleepTracker = () => {
  const { t } = useTranslation();

  return (
    <Tracker trackerType={TrackerType.Sleep} heading={t("healthHub.trackers.sleep.title")} isSyncable={true}>
      <SleepTrackerProgressCard />
      <SleepTrackerChart />
      <SleepDurationInputModal />
    </Tracker>
  );
};
