import { ComponentType, PropsWithChildren, SVGProps } from "react";
import { RoundedCard } from "shared/UI/RoundedCard/RoundedCard";

export type ResourceNavButtonProps = {
  title: string;
  ariaLabel: string;
  ResourceIcon: ComponentType<SVGProps<SVGSVGElement>>;
  onClick: VoidFunction;
  active: boolean;
} & PropsWithChildren;

export const ResourceTile = ({ title, ariaLabel, onClick, ResourceIcon, active }: ResourceNavButtonProps) => {
  return (
    <button aria-label={ariaLabel} onClick={onClick} className="w-1/3" disabled={!active}>
      <RoundedCard className="flex flex-col items-center h-full min-h-[140px] !p-2 basis-1/2">
        <ResourceIcon className="w-16" />
        <div className="flex flex-col h-full justify-center basis-1/2">
          <p>{title}</p>
        </div>
      </RoundedCard>
    </button>
  );
};
ResourceTile.defaultProps = { active: true };
