import { t } from "i18next";
import { RoundedCard } from "shared/UI/RoundedCard/RoundedCard";
import { IconButton } from "UIPalette/IconButton/IconButton";
import { Mood, moodFace } from "./moodFactories";
import { useTrackerContext } from "core/state/Trackers/TrackerContext";
import { TrackerType } from "core/state/Trackers/TrackerType";
import { ViewStateWrapper } from "UIPalette/ViewStateWrapper/ViewStateWrapper";

export const MoodTrackerHealthHubId = "moodTrackerComponent";

export const MoodTrackerCard = () => {
  const { setCurrentValue: setProgress, trackerValuesError, trackerValuesLoading, refreshTrackerValues } = useTrackerContext();

  return (
    <RoundedCard>
      <h4>{t("home.moodTracker.title")}</h4>
      <ViewStateWrapper
        loading={trackerValuesLoading}
        error={trackerValuesError}
        errorMessage={t("home.moodTracker.error")}
        onRetry={refreshTrackerValues}
      >
        <div className="flex flex-row justify-evenly" id={MoodTrackerHealthHubId}>
          {Object.values(Mood).map((mood) => (
            <IconButton
              key={mood}
              ariaLabel={t<string>([`home.moodTracker.myCurrentMood.${mood}`, "home.moodTracker.myCurrentMood.1"])}
              active={true}
              icon={moodFace(mood as Mood)}
              handleClick={() => setProgress(TrackerType.Mood, mood as Mood)}
            />
          ))}
        </div>
      </ViewStateWrapper>
    </RoundedCard>
  );
};
