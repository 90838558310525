import { t } from "i18next";
import { Icon } from "UIPalette/Icon/Icon";
import { ContactCardWithButton } from "./ContactCard/ContactCardWithButton";
import { ReactComponent as WisdomImage } from "shared/assets/Icons/wisdomai-logo.svg";
import "shared/extensions/string";
import { externalLink } from "core/links";

export const WisdomButtonCard = () => {
  const WisdomIcon = () => <Icon icon={<WisdomImage width="48" height="48" />} hasDropShadow={false} />;

  return (
    <ContactCardWithButton
      buttonClassName="orange-button"
      buttonText={t("contactUs.wisdomAI.button")}
      handleClick={() => externalLink(process.env.REACT_APP_WISDOM_AI_URI!)}
      title={t("contactUs.wisdomAI.title")}
      icon={<WisdomIcon />}
      description={t("contactUs.wisdomAI.description")}
    />
  );
};
