import { Route, Routes } from "react-router-dom";

import ArticleCategoryLibrary from "./views/ArticleCategoryLibrary/ArticleCategoryLibrary";
import { BreathingExercises } from "./views/BreathingExercises/BreathingExercises";
import { CompleteMiniHealthCheck } from "./views/CompleteMiniHealthCheck/CompleteMiniHealthCheck";
import { FourWeekPlans } from "./views/FourWeekPlans/FourWeekPlans";
import { MiniHealthChecks } from "./views/MiniHealthChecks/MiniHealthChecks";
import { ProtectedModuleRoute } from "core/auth/ProtectedModuleRoute";
import { ResourcesHome } from "./views/ResourcesHome/ResourcesHome";
import { ViewArticle } from "./views/ViewArticle/ViewArticle";
import { ViewFourWeekPlan } from "./views/ViewFourWeekPlan/ViewFourWeekPlan";
import { WellKnownLearningMaterialCategories } from "UIPalette/LearningMaterials/wellKnownLearningMaterials";
import WisdomModules from "core/Modules";
import { ukLocale } from "shared/core/locale";
import { useUserContext } from "core/state/UserContext";

export const Resources = () => {
  const { user } = useUserContext();
  return (
    <Routes>
      <Route index element={<ResourcesHome />} />
      <Route element={<ProtectedModuleRoute wisdomModule={WisdomModules.fourWeekPlan} />}>
        <Route path="4-week-plans" element={<FourWeekPlans />} />
        <Route path="4-week-plans/:fourWeekPlanId" element={<ViewFourWeekPlan />} />
      </Route>
      <Route element={<ProtectedModuleRoute wisdomModule={WisdomModules.miniHealthChecks} />}>
        <Route path="mini-health-checks" element={<MiniHealthChecks />} />
        <Route path="mini-health-checks/:miniHealthCheckId" element={<CompleteMiniHealthCheck />} />
      </Route>
      <Route path="breathing-exercises" element={<BreathingExercises />} />
      {user?.localeId === ukLocale && (
        <>
          <Route path="bright-tv" element={<ArticleCategoryLibrary category={WellKnownLearningMaterialCategories.brightTV} hideInterests={true} />} />
          <Route path="bright-tv/:articleId" element={<ViewArticle />} />
          <Route path="podcasts" element={<ArticleCategoryLibrary category={WellKnownLearningMaterialCategories.podcasts} />} />
          <Route path="podcasts/:articleId" element={<ViewArticle />} />
        </>
      )}
      <Route path="audio-and-video" element={<ArticleCategoryLibrary category={WellKnownLearningMaterialCategories.audioAndVideo} />} />
      <Route path="audio-and-video/:articleId" element={<ViewArticle />} />
      <Route path="articles" element={<ArticleCategoryLibrary category={WellKnownLearningMaterialCategories.articles} />} />
      <Route path="articles/:articleId" element={<ViewArticle />} />
      <Route
        path="newsletters"
        element={<ArticleCategoryLibrary category={WellKnownLearningMaterialCategories.newsletters} hideInterests={true} />}
      />
      <Route path="newsletters/:articleId" element={<ViewArticle />} />
      <Route path="/:articleId" element={<ViewArticle />} />
    </Routes>
  );
};
