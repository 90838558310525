import { useTranslation } from "react-i18next";
import { useListMiniHealthChecksFunctionRequest } from "shared/request/myHealthyAdvantageApi";
import { PageHeader } from "UIPalette/PageHeader/PageHeader";
import { MiniHealthChecksList } from "./components/MiniHealthChecksList";
import cn from "classnames";
import { useNavigate } from "react-router-dom";
import { CurvedTopBanner } from "UIPalette/CurvedTopBanner/CurvedTopBanner";
import { ViewStateWrapper } from "UIPalette/ViewStateWrapper/ViewStateWrapper";

export const MiniHealthChecks = () => {
  const { t } = useTranslation();
  const navigateHook = useNavigate();
  const { data, isLoading, error, mutate } = useListMiniHealthChecksFunctionRequest({});

  const navigateToCompleteHealthCheck = (miniHealthCheckId: string) => {
    navigateHook(`/resources/mini-health-checks/${miniHealthCheckId}`);
  };

  const hasCompletedMiniHealthChecks = data && data.completed.length > 0;
  const hasAvailableMiniHealthChecks = data && data.available.length > 0;

  return (
    <>
      <CurvedTopBanner>
        <PageHeader title={t("miniHealthChecks.title")} backRoute="/resources" />
      </CurvedTopBanner>
      <ViewStateWrapper loading={isLoading} error={!!error} errorMessage={t("miniHealthChecks.errorList")} onRetry={mutate} isFullScreenView={true}>
        <p className="col-start-1 sm:col-span-2 pb-3">{t("miniHealthChecks.intro")}</p>

        {hasAvailableMiniHealthChecks && (
          <div className="col-start-1 mb-5">
            <MiniHealthChecksList
              title={t("miniHealthChecks.availableHealthChecksTitle")}
              checks={data.available}
              showQuestionCount={true}
              handleClick={navigateToCompleteHealthCheck}
            />
          </div>
        )}

        {hasCompletedMiniHealthChecks && (
          <div className={cn({ "col-start-1": !hasAvailableMiniHealthChecks })}>
            <MiniHealthChecksList
              title={t("miniHealthChecks.completedHealthChecksTitle")}
              checks={data.completed}
              showQuestionCount={false}
              handleClick={navigateToCompleteHealthCheck}
            />
          </div>
        )}
      </ViewStateWrapper>
    </>
  );
};
