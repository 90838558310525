import { CheckBox } from "UIPalette/Components/CheckBox/CheckBox";
import { ViewStateWrapper } from "UIPalette/ViewStateWrapper/ViewStateWrapper";
import classNames from "classnames";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { BurgerMenu } from "shared/UI/BurgerMenu/BurgerMenu";
import StylableButton from "shared/UI/Buttons/StylableButton";
import { Hideable } from "shared/UI/Hideable/Hideable";
import useComponentVisible from "shared/UI/Hooks/useComponentVisible";
import { ReactComponent as Filter } from "shared/assets/Icons/filter.svg";
import { useGetUserInterestPreferencesFunctionRequest } from "shared/request/myHealthyAdvantageApi";

type ArticleFilterMenuProps = {
  preSelectedFilters?: string[];
  onApply: (filters: string[]) => void;
};

export const ArticleFilterMenu = ({ preSelectedFilters = [], onApply }: ArticleFilterMenuProps) => {
  const { t } = useTranslation();
  const { targetAreaRef, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const [selectedFilters, setSelectedFilters] = useState<string[]>(preSelectedFilters);
  const { data, error, isLoading, mutate: refresh } = useGetUserInterestPreferencesFunctionRequest({}, { showErrorIfStale: false });

  const sortedFilters = useMemo(() => {
    return data?.interestPreferences.sort((a, b) => a.name.localeCompare(b.name)) ?? [];
  }, [data]);

  return (
    <div>
      <button
        className={classNames("rounded-full mb-2 px-2 py-2 drop-shadow-lg bg-white", { "pointer-events-none": isComponentVisible })}
        aria-label={t("resources.articleLibraryFilters.openMenuButtonLabel")}
        onClick={() => setIsComponentVisible(true)}
      >
        <Filter className="h-7 md:h-9 text-primary-700 hover:text-primary-600" />
        <Hideable hidden={preSelectedFilters.length === 0}>
          <div
            className={classNames(
              "pointer-events-none text-white absolute top-[-8px] md:top-[-10px] right-[-4px] md:right-[-5px]",
              "rounded-full bg-accent-600  w-[18px] md:w-[22px] h-[18px] md:h-[22px] leading-[18px] md:leading-[22px] text-base font-bold"
            )}
          >
            {preSelectedFilters.length}
          </div>
        </Hideable>
      </button>
      <div ref={targetAreaRef}>
        <BurgerMenu
          isOpen={isComponentVisible}
          onCloseClick={() => setIsComponentVisible(false)}
          onCloseFinished={() => setSelectedFilters(preSelectedFilters)}
          heading={t("resources.articleLibraryFilters.heading")}
          closeButtionAriaLabel={t("resources.articleLibraryFilters.closeMenuButtonLabel")}
        >
          <ViewStateWrapper loading={isLoading} error={!!error} errorMessage={t("resources.articleLibraryFilters.error")} onRetry={refresh}>
            <div className="flex flex-col pt-6 text-sm">
              {sortedFilters.map((filter) => {
                return (
                  <span className="border-b-[1px] py-1 !text-xs" key={filter.categoryId}>
                    <CheckBox
                      checked={selectedFilters.includes(filter.categoryId)}
                      label={filter.name}
                      onChange={(e) => {
                        const isSelected = e.target.checked;
                        if (isSelected) {
                          setSelectedFilters([...selectedFilters, filter.categoryId]);
                        } else {
                          setSelectedFilters(selectedFilters.filter((x) => x !== filter.categoryId));
                        }
                      }}
                      checkboxPosition="right"
                    />
                  </span>
                );
              })}
              <StylableButton
                className="mt-4"
                onClick={() => {
                  onApply(selectedFilters);
                  setIsComponentVisible(false);
                }}
                fullWidth
                color="primary"
                text={t("resources.articleLibraryFilters.applyButtonLabel")}
              />
            </div>
          </ViewStateWrapper>
        </BurgerMenu>
      </div>
    </div>
  );
};
