import { ReactElement, cloneElement, useId, useMemo } from "react";
import { ReactComponent as ErrorIcon } from "./assets/error.svg";
import { ReactComponent as WarningIcon } from "./assets/warning.svg";
import { ReactComponent as SuccessIcon } from "./assets/success.svg";

export type ValidatableWrapperProps = {
  validationMessage?: string;
  validationState?: "success" | "error" | "warning";
};

export const ValidatableWrapper = ({ validationMessage, validationState, children }: ValidatableWrapperProps & { children: ReactElement }) => {
  const uniqueId = useId();
  const validationMessageId = !!validationMessage ? `validation-message-${uniqueId}` : undefined;
  const canShowMessage = !!validationState && !!validationMessage;

  const validationComponent = useMemo(() => {
    switch (validationState) {
      case "error":
        return (
          <>
            <ErrorIcon width={16} height={17} className="self-end" />
            <p id={validationMessageId} className="pl-1 text-m-red text-sm">
              {validationMessage}
            </p>
          </>
        );
      case "warning":
        return (
          <>
            <WarningIcon width={16} height={16} className="self-end" />
            <p id={validationMessageId} className="pl-1 text-d-yellow text-sm">
              {validationMessage}
            </p>
          </>
        );
      case "success":
        return (
          <>
            <SuccessIcon width={16} height={18} className="self-center" />
            <p id={validationMessageId} className="pl-1 text-m-green text-sm">
              {validationMessage}
            </p>
          </>
        );
    }
  }, [validationMessage, validationMessageId, validationState]);

  return (
    <div className="mb-1">
      {cloneElement(children, { "aria-describedby": canShowMessage ? validationMessageId : undefined }, children.props.children)}
      <div className="flex mt-auto h-[18px] items-end">{canShowMessage ? validationComponent : <></>}</div>
    </div>
  );
};
