import { createContext, PropsWithChildren, useContext, useMemo, useState } from "react";
import { Interest, UserResponseModel } from "shared/request/myHealthyAdvantageApi";
import { UserState } from "./userState";
import { WisdomModule } from "core/Modules";

export type UserContextType = {
  user: UserResponseModel | undefined;
  saveUser: (user: UserResponseModel) => void;
  updateUserInterests: (interests: Interest[]) => void;
  isAdditionalInfoComplete: () => boolean;
  isPermitted: (module: WisdomModule) => boolean;
};

export const UserContext = createContext<UserContextType>({} as UserContextType);

export const useUserContext = () => useContext(UserContext);

export const UserContextProvider = (props: PropsWithChildren) => {
  const [user, setUser] = useState<UserResponseModel | undefined>(UserState.get());

  const contextValue = useMemo<UserContextType>(() => {
    const saveUser = (user: UserResponseModel) => {
      setUser(user);
      UserState.set(user);
    };

    const updateUserInterests = (interests: Interest[]) => {
      const updatedUser: UserResponseModel = { ...user!, interests };
      saveUser(updatedUser);
    };

    const isAdditionalInfoComplete = () =>
      !!user && !!user.forenames && !!user.surname && !!user.gender && !user.gender.isNullOrEmpty() && !!user.dateOfBirth;

    function isPermitted(module: WisdomModule) {
      return user?.client?.modules?.includes(module) ?? false;
    }

    return {
      user,
      saveUser,
      updateUserInterests,
      isAdditionalInfoComplete,
      isPermitted,
    };
  }, [user]);

  return <UserContext.Provider value={contextValue}>{props.children}</UserContext.Provider>;
};
