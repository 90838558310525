import { useNavigate } from "react-router-dom";

import { ReactComponent as Meditation } from "shared/assets/Icons/meditation.svg";
import { ReactComponent as Fitness } from "shared/assets/Icons/fitness.svg";
import { ReactComponent as Recipes } from "shared/assets/Icons/recipes.svg";
import { t } from "i18next";
import { WellKnownLearningMaterialSubCategories } from "UIPalette/LearningMaterials/wellKnownLearningMaterials";
import { ResourceTile } from "../ResourceTile";

export const WellbeingResourceTiles = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-row justify-around text-lg lg:text-xl w-full h-fit gap-2 md:gap-4">
      <ResourceTile
        title={t("resources.meditationLinkName")}
        ariaLabel={t("resources.meditationNavigationLabel")}
        ResourceIcon={Meditation}
        onClick={() => navigate(`/resources/audio-and-video/#si=${WellKnownLearningMaterialSubCategories.sleep}`, { state: { hideInterests: true } })}
      />
      <ResourceTile
        title={t("resources.recipesLinkName")}
        ariaLabel={t("resources.recipesnNavigationLabel")}
        ResourceIcon={Recipes}
        onClick={() => navigate(`/resources/articles/#si=${WellKnownLearningMaterialSubCategories.recipes}`, { state: { hideInterests: true } })}
      />
      <ResourceTile
        title={t("resources.fitnessLinkName")}
        ariaLabel={t("resources.fitnessNavigationLabel")}
        ResourceIcon={Fitness}
        onClick={() =>
          navigate(`/resources/audio-and-video/#si=${WellKnownLearningMaterialSubCategories.improvingYourPhysicalHealth}`, {
            state: { hideInterests: true },
          })
        }
      />
    </div>
  );
};
