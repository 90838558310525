import { ChangeEventHandler } from "react";
import { useListDivisionsFunctionRequest } from "shared/request/myHealthyAdvantageApi";
import { useTranslation } from "react-i18next";
import { NoValueSelected } from "shared/constants/SelectConstants";
import { Select } from "shared/UI/Inputs/Select/Select";

type DivisionSelectProps = {
  onChange: ChangeEventHandler;
  label: string;
  value?: string;
};

export const DivisionSelect = ({ onChange, label, value }: DivisionSelectProps) => {
  const divisionsFunctionRequest = useListDivisionsFunctionRequest();
  const { t } = useTranslation();
  return (
    <Select onChange={onChange} label={label} value={value}>
      <option key={0} value={NoValueSelected}>
        {t("division.defaultDivision")}
      </option>
      {divisionsFunctionRequest.data?.divisions?.map((d) => (
        <option key={d.id} value={d.id}>
          {d.name}
        </option>
      ))}
    </Select>
  );
};
