import { AddWaterIntakeCard } from "./components/AddWaterIntakeCard/AddWaterIntakeCard";
import { TrackerType } from "core/state/Trackers/TrackerType";
import { WaterTrackerChart } from "./components/WaterTrackerChart";
import { WaterTrackerProgressCard } from "./components/Progress/WaterTrackerProgressCard";
import { useTranslation } from "react-i18next";
import { Tracker } from "../components/Tracker";

export const WaterTracker = () => {
  const { t } = useTranslation();

  return (
    <Tracker trackerType={TrackerType.Water} heading={t("healthHub.trackers.water.heading")} isSyncable={true}>
      <WaterTrackerProgressCard />
      <WaterTrackerChart />
      <AddWaterIntakeCard />
    </Tracker>
  );
};
