import { t } from "i18next";
import { NavLink, Outlet } from "react-router-dom";
import HealthAssuredLogo from "shared/assets/HealthAssuredLogo.png";

export const LoginInfoLayout = () => {
  return (
    <div className="flex flex-col min-h-screen gap-0 overflow-clip">
      <header role="banner" className="flex flex-row bg-white h-20 w-full sticky top-0 z-OVERLAY drop-shadow-md md:px-[4rem]">
        <NavLink
          className="flex-grow lg:flex-grow-0 h-full px-3 flex xs:pointer-events-none lg:pointer-events-auto w-4/6 sm:w-auto items-center"
          aria-label={t("navigation.homeNavigationLabel")}
          to={"/"}
        >
          <img alt={t("navigation.healthAssuredlogo")} src={HealthAssuredLogo} className="max-h-14 h-full" />
        </NavLink>
      </header>
      <main role="main">
        <div className="flex flex-col w-full mx-auto lg:max-w-[1761px] mb-6 px-6 md:px-[5rem]">
          <Outlet />
        </div>
      </main>
    </div>
  );
};
