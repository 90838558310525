import { CheckBox } from "UIPalette/Components/CheckBox/CheckBox";
import { ViewStateWrapper } from "UIPalette/ViewStateWrapper/ViewStateWrapper";
import { useUserContext } from "core/state/UserContext";
import { t } from "i18next";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import ErrorAlert from "shared/UI/Alerts/ErrorAlert";
import StylableButton from "shared/UI/Buttons/StylableButton";
import { DestinationState } from "shared/core/state/destinationState";
import { useGetUserInterestPreferencesFunctionRequest, useUpdateUserInterestsFunctionRequest } from "shared/request/myHealthyAdvantageApi";

export const Preferences = () => {
  const [dislayErrorMessage, setDislayErrorMessage] = useState(false);
  const [selectedPreferences, setSelectedPreferences] = useState<string[]>([]);
  const navigate = useNavigate();
  const { data, error, isLoading, mutate: refresh } = useGetUserInterestPreferencesFunctionRequest({});
  const { trigger } = useUpdateUserInterestsFunctionRequest({});
  const { updateUserInterests } = useUserContext();

  const sortedPreferences = useMemo(() => {
    const preferences = !!data?.interestPreferences ? [...data?.interestPreferences] : [];
    return preferences.sort((a, b) => a.name.localeCompare(b.name));
  }, [data?.interestPreferences]);

  const savePreference = async () => {
    try {
      const response = await trigger({ body: { interestCategoryIds: selectedPreferences } });
      updateUserInterests(response?.data.interests ?? []);
      await refresh();
      navigate(DestinationState.get());
    } catch {
      setDislayErrorMessage(true);
    }
  };

  const updateInterests = (preferenceCategoryId: string, isSelected: boolean) => {
    let preferencesToUpdate = [...selectedPreferences];
    if (isSelected) {
      preferencesToUpdate = [...preferencesToUpdate, preferenceCategoryId];
    } else {
      preferencesToUpdate = preferencesToUpdate.filter((x) => x !== preferenceCategoryId);
    }
    setSelectedPreferences(preferencesToUpdate);
  };

  return (
    <ViewStateWrapper loading={isLoading} error={!!error} errorMessage={t("preferences.error")} onRetry={refresh} isFullScreenView={true}>
      <p className="mb-2">{t("preferences.intro")}</p>
      <div className="flex  flex-col">
        {sortedPreferences.map((interest) => (
          <div className="py-1" key={interest.categoryId}>
            <CheckBox
              checked={selectedPreferences.includes(interest.categoryId)}
              onChange={(e) => updateInterests(interest.categoryId, e.target.checked)}
              label={interest.name}
            />
          </div>
        ))}
        <StylableButton
          disabled={selectedPreferences.length === 0}
          className="bright-button w-full mt-2"
          fullWidth
          color="primary"
          type="button"
          text={t("preferences.save")}
          onClick={async () => await savePreference()}
        />
      </div>
      {dislayErrorMessage && <ErrorAlert content={t("preferences.updateFailure")} />}
    </ViewStateWrapper>
  );
};
