import { StructuredText, StructuredTextDocument } from "react-datocms";
import Button from "@brighthr/component-button";
import { CurvedTopBanner } from "UIPalette/CurvedTopBanner/CurvedTopBanner";
import { Hideable } from "shared/UI/Hideable/Hideable";
import { PageHeader } from "UIPalette/PageHeader/PageHeader";
import { externalLink } from "core/links";
import { useGetLearningMaterialFunctionRequest } from "shared/request/myHealthyAdvantageApi";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./ViewArticle.css";
import { ViewStateWrapper } from "UIPalette/ViewStateWrapper/ViewStateWrapper";
import { useMemo } from "react";
import { FileDownloader } from "./components/FileDownloader";
import { NotFound } from "../../../../Views/NotFound";
import { datocmsUUIDRegex } from "shared/utils/regex";
import { VideoPlayer } from "./components/MediaPlayer/VideoPlayer";
import { WebAudioPlayer } from "./components/MediaPlayer/WebAudioPlayer";
import { useMobilePlatform } from "core/hooks/useMobilePlatform";
import { MobileAudioPlayer } from "./components/MediaPlayer/MobileAudioPlayer";

export const ViewArticle = () => {
  const { t } = useTranslation();
  const { isMobileApp, backgroundAudioEnabled } = useMobilePlatform();
  const { articleId } = useParams();
  const isValidArticleId = articleId !== undefined && datocmsUUIDRegex.test(articleId);
  const {
    data: article,
    isLoading,
    error,
    mutate,
  } = useGetLearningMaterialFunctionRequest(
    { id: articleId },
    { revalidateOnMountIfStale: false, showErrorIfStale: false, shouldFetch: isValidArticleId }
  );

  const articleBody = useMemo(() => {
    const hasLink = !article?.link?.isNullOrEmpty() ?? false;
    if (!hasLink) {
      return <></>;
    }

    switch (article?.articleType) {
      case "external_link":
        return (
          <div className="pt-5">
            <Button text={t("viewArticle.openLink")} color="primary" onClick={() => externalLink(article.link!)} />
          </div>
        );
      case "newsletter":
        return <FileDownloader url={article.link!} title={article.title} />;
      case "video":
        return <VideoPlayer link={article.link} />;
      case "podcast":
        return isMobileApp && !!backgroundAudioEnabled ? (
          <MobileAudioPlayer mediaItem={{ src: article.link!, title: article.title }} />
        ) : (
          <WebAudioPlayer link={article.link!} />
        );
      default:
        return <></>;
    }
  }, [article, t, isMobileApp, backgroundAudioEnabled]);

  return (
    <>
      <CurvedTopBanner>
        <PageHeader title={article?.title ?? ""} backRoute={-1} />
      </CurvedTopBanner>
      <ViewStateWrapper loading={isLoading} error={!!error} errorMessage={t("viewArticle.error")} onRetry={mutate} isFullScreenView={true}>
        <Hideable hidden={article === undefined}>
          <div className="col-span-full view-article">
            <StructuredText data={article?.text as StructuredTextDocument} />
            {articleBody}
          </div>
        </Hideable>
        <Hideable hidden={isValidArticleId}>
          <NotFound />
        </Hideable>
      </ViewStateWrapper>
    </>
  );
};
