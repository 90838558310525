import { EqualWebButton } from "UIPalette/EqualWebButton/EqualWebButton";
import { Outlet } from "react-router-dom";
import { ReactComponent as BottomLoginImage } from "shared/assets/BrightLogoColumn.svg";
import { ReactComponent as TopWWLoginImage } from "shared/assets/WisdomLogoDark.svg";
import { ReactComponent as TopHALoginImage } from "shared/assets/ha-full.svg";
import { isHealthAssuredLocale } from "shared/core/locale";

export const LoginLayout = () => {
  return (
    <main role="main">
      <div className="absolute w-full h-full">
        <div className="absolute h-10 right-2">
          <EqualWebButton />
        </div>
        <div className="flex flex-col items-center justify-center h-full gap-16 lg:flex-row lg:justify-center">
          <div className={"w-80 flex flex-col gap-8 items-center justify-center"}>
            {isHealthAssuredLocale ? <TopHALoginImage className={"h-[30px] w-[200px]"} /> : <TopWWLoginImage className={"h-[30px]"} />}
            <BottomLoginImage className={"h-[200px]"} />
          </div>
          <div className="w-3/4 lg:px-5 lg:mr-5 lg:w-80 md:w-1/3">
            <Outlet />
          </div>
        </div>
      </div>
    </main>
  );
};
