import { CardWithButton } from "UIPalette/CardWithButton/CardWithButton";
import { t } from "i18next";
import BrightExchangeLogo from "shared/assets/BrightExchangeLogo.jpg";
import { externalLink } from "core/links";

export const PerksAndDiscountsCard = () => {
  return (
    <CardWithButton
      buttonClassName="orange-button"
      buttonText={t("home.perksAndDiscounts.button")}
      handleClick={() => externalLink(process.env.REACT_APP_PERKS_AND_DISCOUNTS_URI!)}
    >
      <h2 className="text-center pb-3">{t("home.perksAndDiscounts.title")}</h2>
      <img className="mx-auto max-w-[240px]" src={BrightExchangeLogo} alt="Bright Exchange Logo" />
      <p className="pt-3 text-center" role="paragraph">
        {t("home.perksAndDiscounts.description")}
      </p>
    </CardWithButton>
  );
};
