import { Modal, ModalBody } from "UIPalette/Modal/Modal";
import { t } from "i18next";
import { useState } from "react";
import StylableButton from "shared/UI/Buttons/StylableButton";
import { IntegerInput } from "shared/UI/Inputs/IntegerInput/IntegerInput";

export interface SetGoalModalProps {
  heading: string;
  description: string;
  currentGoal: number;
  unit: string;
  incrementStep: number;
  onClose: Function;
  onSave: Function;
}

export const SetGoalModal = ({ heading, description, currentGoal, unit, incrementStep, onClose, onSave }: SetGoalModalProps) => {
  const [goal, setGoal] = useState(currentGoal);

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onSave(goal);
    }
  };

  return (
    <Modal width="xs" close={() => onClose()}>
      <ModalBody>
        <div className="flex flex-col gap-3">
          <h2>{heading}</h2>
          <IntegerInput
            label={t("healthHub.trackers.goal.ariaInput")}
            onChange={(e) => setGoal(parseInt(e.target.value))}
            step={incrementStep}
            value={String(goal)}
            onKeyDown={handleKeyPress}
            min={1}
            maxLength={8}
            suffix={unit}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
          />
          <p role="note">{description}</p>
          <div className="mt-6 flex justify-end gap-x-3">
            <StylableButton
              className="buttons"
              text={t("healthHub.trackers.buttons.back")}
              color="primary"
              outlineButton={true}
              onClick={() => onClose()}
            />
            <StylableButton
              className="buttons"
              text={t("healthHub.trackers.buttons.save")}
              color="primary"
              onClick={() => onSave(goal)}
              disabled={goal === 0}
            />
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
