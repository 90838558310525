import classNames from "classnames";
import "./StylableButton.css";
import Button, { ButtonProps } from "@brighthr/component-button";

declare type StylableButtonProps = ButtonProps & {
  className?: string;
};

const StylableButton = ({ className, ...rest }: StylableButtonProps) => {
  return (
    <div className={classNames(className !== undefined ? className : "default-button", "styled-button")}>
      <Button {...rest} />
    </div>
  );
};

export default StylableButton;
