import { Controller, useForm } from "react-hook-form";

import { AppRoutes } from "core/AppRoutes";
import { CurvedTopBanner } from "UIPalette/CurvedTopBanner/CurvedTopBanner";
import { PageHeader } from "UIPalette/PageHeader/PageHeader";
import { ResponsiveBreakpoints } from "shared/UI/ResponsiveBreakpoints";
import StylableButton from "shared/UI/Buttons/StylableButton";
import Textarea from "@brighthr/component-textarea";
import { t } from "i18next";
import { toastNotify } from "shared/UI/Toaster/Toaster";
import { useContactSupportFunctionRequest } from "shared/request/myHealthyAdvantageApi";
import { useMatchMedia } from "shared/UI/Hooks/useMatchMedia";
import "./ContactSupport.css";

export const ContactSupport = () => {
  const {
    control,
    handleSubmit,
    getValues,
    reset,
    formState: { dirtyFields },
  } = useForm({
    defaultValues: {
      message: "",
    },
  });
  const { trigger: contactSupportAPI } = useContactSupportFunctionRequest();
  const largerDisplay = useMatchMedia(ResponsiveBreakpoints.Desktop, false);

  async function onSubmit() {
    const { message } = getValues();
    try {
      await contactSupportAPI({ body: { message } });
      toastNotify(t("contactSupport.successToast"), "success");
      reset();
    } catch {
      toastNotify(t("contactSupport.errorToast"), "error");
    }
  }

  return (
    <>
      <CurvedTopBanner>
        <PageHeader title={t("contactSupport.title")} backRoute={largerDisplay ? AppRoutes.home : AppRoutes.account} />
      </CurvedTopBanner>
      <div>
        <p>{t("contactSupport.description")}</p>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name={"message"}
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <div className="mt-5 mb-5 support-textarea">
                <Textarea
                  id="message"
                  description=" "
                  maxLength={1000}
                  rows={5}
                  showCounter
                  label={t("contactSupport.supportMessageInput")}
                  onChange={onChange}
                  value={value}
                />
              </div>
            )}
          />

          <StylableButton
            fullWidth
            size="xl"
            color="primary"
            type="submit"
            disabled={!dirtyFields.message}
            text={t("contactSupport.submitButton")}
            aria-label={t("contactSupport.submitButton")}
          />
        </form>
      </div>
    </>
  );
};
