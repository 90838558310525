import { useTranslation } from "react-i18next";
import { MiniHealthCheckQuestion, MiniHealthCheckQuestionOption } from "shared/request/myHealthyAdvantageApi";
import { ModalBody, ModalFooter } from "../../../../../UIPalette/Modal/Modal";
import StylableButton from "shared/UI/Buttons/StylableButton";

export type MiniHealthCheckQuestionnaireQuestionProps = {
  currentQuestion: MiniHealthCheckQuestion;
  currentOption: MiniHealthCheckQuestionOption | undefined;
  nextButtonText: string;
  onPrevious: VoidFunction;
  onNext: VoidFunction;
  onSelectOption: (option: MiniHealthCheckQuestionOption) => void;
};

export const MiniHealthCheckQuestionnaireQuestion = ({
  currentQuestion,
  currentOption,
  nextButtonText,
  onPrevious,
  onNext,
  onSelectOption,
}: MiniHealthCheckQuestionnaireQuestionProps) => {
  const { t } = useTranslation();

  return (
    <>
      <ModalBody>
        <div className="h-80">
          <p className="pb-4">{currentQuestion.title}</p>
          {currentQuestion.options?.map((option) => {
            return (
              <div key={`option-${option.id}`} className="flex flex-row items-center content-start p-2">
                <input
                  className="h-6"
                  type="radio"
                  name="option"
                  id={`option_${option.id}`}
                  value={option.id}
                  onChange={() => onSelectOption(option)}
                />
                <label htmlFor={`option_${option.id}`} className="pl-2">
                  {option.text}
                </label>
              </div>
            );
          })}
        </div>
      </ModalBody>
      <ModalFooter>
        <StylableButton text={t("navigation.previousButton")} outlineButton color="primary" onClick={onPrevious} />
        <StylableButton text={nextButtonText} disabled={currentOption === undefined} color="primary" onClick={onNext} />
      </ModalFooter>
    </>
  );
};
