import { BreathingExercisesCard } from "../../../Home/BreathingExercisesCard";
import { GeneralActivityTrackerProgressTile } from "../../components/GeneralActivityTrackerProgressTile/GeneralActivityTrackerProgressTile";
import { LeaderboardsSingleCard } from "../../components/LeaderboardsSingleCard/LeaderboardsSingleCard";
import { MoodChart } from "./components/MoodChart/MoodChart";
import { MoodTrackerCard } from "UIPalette/MoodTrackerCard/MoodTrackerCard";
import { PageHeader } from "UIPalette/PageHeader/PageHeader";
import { ProtectedModule } from "core/auth/ProtectedModule";
import { SleepTrackerProgressTile } from "../../components/SleepTrackerProgressTile/SleepTrackerProgressTile";
import { StepsTrackerProgressTile } from "../../components/StepsTrackerProgressTile/StepsTrackerProgressTile";
import { TrackerType } from "core/state/Trackers/TrackerType";
import { WaterTrackerProgressTile } from "../../components/WaterTrackerProgressTile/WaterTrackerProgressTile";
import WisdomModules from "core/Modules";
import { t } from "i18next";
import { useTrackerContext } from "core/state/Trackers/TrackerContext";
import { TwoColumnLayout } from "UIPalette/Layout/TwoColumnLayout";

function MoodTrackerComponent(props: { mood: number }) {
  return <>{props.mood > 0 ? <MoodChart /> : <MoodTrackerCard />}</>;
}

function TrackerComponents() {
  return (
    <>
      <p className="mb-5 col-start-1">{t("healthHub.headingParagraph")}</p>
      <div className="grid grid-cols-2 gap-x-11 gap-y-2">
        <WaterTrackerProgressTile />
        <StepsTrackerProgressTile />
        <SleepTrackerProgressTile />
        <GeneralActivityTrackerProgressTile />
      </div>
    </>
  );
}

export const HealthHubHome = () => {
  const { getCurrentValue } = useTrackerContext();
  const mood = getCurrentValue(TrackerType.Mood);

  return (
    <div className="mt-6">
      <PageHeader title={t("healthHub.heading")}></PageHeader>

      <TwoColumnLayout>
        <TwoColumnLayout.Left>
          <ProtectedModule wisdomModule={WisdomModules.trackers} moduleComponent={<TrackerComponents />} />
        </TwoColumnLayout.Left>
        <TwoColumnLayout.Right>
          <ProtectedModule wisdomModule={WisdomModules.moodTracker} moduleComponent={<MoodTrackerComponent mood={mood} />} />
          <ProtectedModule wisdomModule={WisdomModules.leaderBoards} moduleComponent={<LeaderboardsSingleCard />} />
          <BreathingExercisesCard />
        </TwoColumnLayout.Right>
      </TwoColumnLayout>
    </div>
  );
};
