import { Platform, useMobilePlatform } from "core/hooks/useMobilePlatform";
import { useEffect, useState } from "react";

import { InfoModal } from "UIPalette/Modal/InfoModal";
import StylableButton from "shared/UI/Buttons/StylableButton";
import TrackerSync from "core/state/Trackers/TrackerSync";
import { TrackerType } from "core/state/Trackers/TrackerType";
import { useTranslation } from "react-i18next";

export const SyncTrackerDataButton = ({ trackerType }: { trackerType: TrackerType }) => {
  const { t } = useTranslation();
  const [isSyncEnabled, setIsSyncEnabled] = useState(true);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const { platform, isMobileApp } = useMobilePlatform();

  useEffect(() => {
    async function checkSync() {
      const isEnabled = await TrackerSync.isSyncEnabled(trackerType);
      setIsSyncEnabled(isEnabled);
    }

    if (isMobileApp) {
      checkSync();
    }
  }, [isMobileApp, trackerType]);

  if (!isMobileApp || isSyncEnabled) {
    return <></>;
  }

  const createSyncButton = (onClick: VoidFunction) => (
    <StylableButton text={t("healthHub.trackers.syncData.buttonLabel")} color="primary" fullWidth={true} onClick={() => onClick()} />
  );

  switch (platform) {
    case Platform.android:
      return createSyncButton(async () => {
        await TrackerSync.requestDataSync();
        setIsSyncEnabled(true);
      });
    case Platform.ios:
      return (
        <div className="whitespace-pre">
          {createSyncButton(() => setShowInfoModal(true))}
          {showInfoModal && (
            <InfoModal
              title={t("healthHub.trackers.syncData.iOS.modalTitle", { tracker: TrackerType[trackerType].toLowerCase() })}
              message={t("healthHub.trackers.syncData.iOS.modalBody", { app: "Wisdom" })}
              onDismiss={async () => {
                await TrackerSync.requestDataSync();
                setShowInfoModal(false);
                setIsSyncEnabled(true);
              }}
            />
          )}
        </div>
      );
    default:
      return <></>;
  }
};
