import "shared/extensions/date";
import { ComboboxEntry, Options } from "./components/Options/Options";
import { Controller, useForm } from "react-hook-form";
import {
  CreateLeaderboardCommand,
  useCreateLeaderboardFunctionRequest,
  useListLeaderboardsFunctionRequest,
} from "shared/request/myHealthyAdvantageApi";
import { Duration, DurationPicker, calculateEndDate, getDurationText } from "./components/DurationPicker/DurationPicker";
import { LeaderboardPreview, Preview } from "./components/Preview/Preview";
import { TrackerType, toTrackerType } from "core/state/Trackers/TrackerType";
import { useRef, useState } from "react";
import { ErrorAlert } from "shared/UI/Alerts/ErrorAlert";
import { Select } from "shared/UI/Inputs/Select/Select";
import StylableButton from "shared/UI/Buttons/StylableButton";
import { ValidatableDatePicker } from "shared/UI/Inputs/DateInput/ValidatableDatePicker";
import { ValidatableWrapper } from "shared/UI/Inputs/ValidatableWrapper";
import { ViewHeader } from "./components/ViewHeader/ViewHeader";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import { ViewStateWrapper } from "UIPalette/ViewStateWrapper/ViewStateWrapper";

type Fields = {
  type: TrackerType | undefined;
  name: string;
  startingDate: Date;
};

export const LeaderboardCreate = () => {
  const durations: Duration[] = [
    { value: 1, unit: "week" },
    { value: 1, unit: "month" },
    { value: 3, unit: "month" },
    { value: 6, unit: "month" },
  ];

  const [selectedDuration, setSelectedDuration] = useState(0);
  const startDate = useRef(new Date());
  const [endDate, setEndDate] = useState(new Date().addWeeks(1));
  const [errorMessage, setErrorMessage] = useState(false);
  const [types, setTypes] = useState<ComboboxEntry[] | undefined>();
  const [names, setNames] = useState<ComboboxEntry[] | undefined>();
  const [leaderboardPreview, setLeaderboardPreview] = useState<LeaderboardPreview | undefined>();

  const { data: leaderboardData, isLoading, error, mutate } = useListLeaderboardsFunctionRequest({});
  const { trigger: createLeaderboard } = useCreateLeaderboardFunctionRequest({});
  const navigate = useNavigate();

  function getDistinctTypes(): ComboboxEntry[] {
    return leaderboardData!
      .map<ComboboxEntry>((x) => ({ text: x.type.name, value: x.type.identifier }))
      .reduce<ComboboxEntry[]>((uniqueTypes, entry) => {
        if (!uniqueTypes.some((x) => x.value === entry.value)) {
          uniqueTypes.push(entry);
        }
        return uniqueTypes;
      }, []);
  }

  function getNamesForType(typeKey: string): ComboboxEntry[] {
    return leaderboardData!.filter((x) => x.type.identifier === typeKey).map((x) => ({ text: x.name, value: x.id }));
  }

  function onPreview(fields: Fields) {
    setErrorMessage(false);
    const leaderboard = leaderboardData!.filter((x) => x.id === fields.name)[0];
    setLeaderboardPreview({
      leaderboard,
      durationText: getDurationText(durations[selectedDuration]),
      startDate: startDate.current,
      endDate: endDate,
    });
  }

  const onCreate = async () => {
    try {
      const command: CreateLeaderboardCommand = {
        id: leaderboardPreview!.leaderboard.id,
        name: leaderboardPreview!.leaderboard.name,
        trackerType: toTrackerType(leaderboardPreview!.leaderboard.type.identifier),
        startDate: leaderboardPreview!.startDate.toDateOnly(),
        endDate: leaderboardPreview!.endDate.toDateOnly(),
      };

      const response = await createLeaderboard({ body: command });
      navigate(`/leaderboards/${response?.data.code}`, { replace: true });
    } catch {
      setErrorMessage(true);
    }
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    resetField,
  } = useForm<Fields>({
    defaultValues: {
      type: undefined,
      name: "",
      startingDate: startDate.current,
    },
  });

  if (error || isLoading) {
    return (
      <>
        <ViewHeader />
        <ViewStateWrapper loading={isLoading} error={!!error} errorMessage={t("leaderboards.errors.load")} onRetry={mutate} isFullScreenView={true} />
      </>
    );
  }

  if (types === undefined) {
    setTypes(getDistinctTypes());
  }

  if (leaderboardPreview) {
    return (
      <Preview
        name={leaderboardPreview.leaderboard.name}
        content={leaderboardPreview.leaderboard.description}
        subtitle={`${
          leaderboardPreview.durationText
        } (${leaderboardPreview.startDate.toLocaleDateString()} - ${leaderboardPreview.endDate.toLocaleDateString()})`}
        onClose={() => setLeaderboardPreview(undefined)}
      >
        <StylableButton
          className="bright-button preview-button"
          fullWidth
          color="primary"
          type="button"
          text={t("leaderboards.create.createLeaderboardButton")}
          aria-label={t("leaderboards.create.createLeaderboardButton")}
          onClick={() => onCreate()}
        />
      </Preview>
    );
  }

  const defaultTypeValue = t("leaderboards.create.typePlaceholder");
  const defaultNameValue = t("leaderboards.create.namePlaceholder");
  return (
    <>
      <ViewHeader />
      <div className="col-start-1">
        <form className="mt-8 px-5" onSubmit={handleSubmit(onPreview)}>
          <Controller
            name="type"
            control={control}
            rules={{
              required: true,
              validate: (value) => value?.toString() !== defaultTypeValue,
            }}
            render={({ field: { onChange, value } }) => (
              <ValidatableWrapper
                validationMessage={t("leaderboards.create.validationErrors.typeRequired")}
                validationState={errors.type ? "error" : undefined}
              >
                <Select
                  onChange={(event) => {
                    setNames(getNamesForType(event.target.value));
                    onChange(event);
                    resetField("name");
                  }}
                  label={t("leaderboards.create.typeLabel")}
                  value={value ?? ""}
                >
                  <Options defaultValue={defaultTypeValue} options={types} />
                </Select>
              </ValidatableWrapper>
            )}
          />

          <Controller
            name="name"
            control={control}
            rules={{
              required: true,
              validate: (value) => value !== defaultNameValue,
            }}
            render={({ field: { onChange, value } }) => (
              <ValidatableWrapper
                validationMessage={t("leaderboards.create.validationErrors.nameRequired")}
                validationState={errors.name ? "error" : undefined}
              >
                <Select onChange={onChange} label={t("leaderboards.create.nameLabel")} value={value ?? ""}>
                  <Options defaultValue={defaultNameValue} options={names} />
                </Select>
              </ValidatableWrapper>
            )}
          />

          <Controller
            name={"startingDate"}
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <ValidatableDatePicker
                label={t("leaderboards.create.startingDateLabel")}
                date={value}
                setDate={(value: Date | undefined) => {
                  if (value) {
                    startDate.current = value;
                    onChange(value);
                    setEndDate(calculateEndDate(startDate.current, durations[selectedDuration]));
                  }
                }}
              />
            )}
          />

          <p>
            {t("leaderboards.create.endingDateLabel")} {endDate.toLocaleDateString()}
          </p>

          <DurationPicker
            className="mt-6 !bg-transparent"
            heading={t("leaderboards.create.duration")}
            durations={durations}
            initialSelection={selectedDuration}
            onSelectionChanged={(index: number) => {
              setSelectedDuration(index);
              setEndDate(calculateEndDate(startDate.current, durations[index]));
            }}
          />

          <StylableButton
            className="bright-button mt-6"
            fullWidth
            color="primary"
            type="submit"
            text={t("leaderboards.create.previewLeaderboardButton")}
            aria-label={t("leaderboards.create.previewLeaderboardButton")}
          />
        </form>

        {errorMessage && <ErrorAlert content={t("leaderboards.create.error")} />}
      </div>
    </>
  );
};
