import "./index.css";
import "./i18n"; // Bootstrap internationalisation
import "shared/core/types/authStorage";

import { AuthProvider, AuthProviderProps } from "react-oidc-context";
import { ErrorFallback, handleError } from "./ErrorFallback";
import { Log, User, UserManager, UserManagerSettings, WebStorageStateStore } from "oidc-client-ts";
import { appInsightsLogger, initialiseAppInsights, reactPlugin, sendToAppInsights } from "core/monitoring/AppInsights";

import { App } from "./App";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { BrowserRouter } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import React from "react";
import ReactDOM from "react-dom/client";
import { TokenRenewalContextProvider } from "core/auth/TokenRenewalContext";
import { changeLanguage } from "i18next";
import reportWebVitals from "./reportWebVitals";
import { userLocale } from "shared/core/locale";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

const redirectUri = `${window.location.origin}/auth`;
window.authStorage = !!window.flutter_inappwebview ? localStorage : sessionStorage;
const userManagerSettings: UserManagerSettings = {
  authority: process.env.REACT_APP_AUTH_AUTHORITY!,
  client_id: process.env.REACT_APP_AUTH_CLIENT_ID!,
  redirect_uri: redirectUri,
  automaticSilentRenew: true,
  silent_redirect_uri: redirectUri,
  scope: "offline_access openid",
  post_logout_redirect_uri: `${window.location.origin}/login`,
  userStore: new WebStorageStateStore({
    store: window.authStorage,
  }),
};
const userManager = new UserManager(userManagerSettings);

const authProviderConfig: AuthProviderProps = {
  userManager,
  onSigninCallback: (_user: User | void): void => {
    window.history.replaceState({}, document.title, window.location.pathname);
  },
};

initialiseAppInsights();
Log.setLogger(appInsightsLogger);

changeLanguage(userLocale);

root.render(
  <React.StrictMode>
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={handleError} onReset={() => (window.location.href = "/home")}>
      <BrowserRouter>
        <AuthProvider {...authProviderConfig}>
          <AppInsightsContext.Provider value={reactPlugin}>
            <TokenRenewalContextProvider userManager={userManager}>
              <App />
            </TokenRenewalContextProvider>
          </AppInsightsContext.Provider>
        </AuthProvider>
      </BrowserRouter>
    </ErrorBoundary>
  </React.StrictMode>
);

reportWebVitals(sendToAppInsights);
