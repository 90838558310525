import enDefaultNamespaceTranslations from "./locales/en/translations.json";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import wisdomWellbeingDefaultNamespaceTranslations from "./locales/wisdomwellbeing/translations.json";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: enDefaultNamespaceTranslations,
      },
      "en-AU": {
        translation: wisdomWellbeingDefaultNamespaceTranslations,
      },
      // en-WW is a custom locale referring to english translation with Wisdom Wellbeing brand name
      "en-WW": {
        translation: wisdomWellbeingDefaultNamespaceTranslations,
      },
    },
    fallbackLng: "en-WW",
    defaultNS: "translation",
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });
