import classNames from "classnames/dedupe";
import { ForwardedRef, HTMLInputTypeAttribute, ReactElement, useId, useMemo, useState } from "react";
import { ReactComponent as CalendarIcon } from "../assets/calendar.svg";
import { ReactComponent as SearchIcon } from "../assets/search.svg";
import { ReactComponent as ClockIcon } from "../assets/clock.svg";
import "./Input.css";
import React from "react";

export type InputProps = {
  label?: string;
  icon?: ReactElement;
  iconPosition?: "left" | "right";
  type: Extract<HTMLInputTypeAttribute, "number" | "tel" | "search" | "text" | "date" | "time">;
  validationState?: "error" | "warning" | "success";
  /**
   * Height should be provided in tailwind format, e.g. h-10
   */
  height?: string;
  /**
   * width should be provided in tailwind format, e.g. w-10
   */
  width?: string;
  suffix?: string;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "width" | "height" | "type" | "className">;

export const Input = React.forwardRef(
  (
    { id, type, label, width, height, disabled = false, icon, iconPosition = "right", validationState, suffix, ...props }: InputProps,
    _ref?: ForwardedRef<HTMLInputElement>
  ): ReactElement => {
    iconPosition = type === "date" || type === "time" ? "right" : iconPosition;
    const uniqueId = useId();
    const inputId = id ? id : `input-${uniqueId}`;
    const [internalHasValue, setInternalHasValue] = useState<boolean>(props.defaultValue !== undefined && props.defaultValue !== "");
    const isControlledCompnent = props.value !== undefined;
    const hasValue = isControlledCompnent ? props.value !== "" : internalHasValue;

    const displayedIcon = useMemo(() => {
      if (icon) {
        return icon;
      }

      switch (type) {
        case "date":
          return <CalendarIcon className="h-6 w-6" />;
        case "search":
          return <SearchIcon className="h-9 w-9" />;
        case "time":
          return <ClockIcon className="h-6 w-6" />;
        default:
          return undefined;
      }
    }, [icon, type]);

    return (
      <div className={`relative flex flex-col ${height ?? "h-12"} ${width ?? "w-full"}`}>
        <div className={classNames(`h-full w-full`)}>
          <input
            {...props}
            ref={_ref}
            type={type === "search" ? "text" : type}
            onChange={(e) => {
              if (!isControlledCompnent) {
                setInternalHasValue(e.currentTarget.value !== undefined && e.currentTarget.value !== "");
              }
              props.onChange?.(e);
            }}
            onMouseDown={(e) => {
              if (type === "date" || type === "time") {
                e.currentTarget.showPicker();
              }
              props.onMouseDown?.(e);
            }}
            className={classNames("peer input-base", {
              "pr-8": !!displayedIcon && iconPosition === "right" && type !== "date" && type !== "time",
              "pl-10": !!displayedIcon && iconPosition === "left",
              "pr-20": !!suffix,
              "pt-2": !!label && hasValue,
              "!text-base": hasValue || props.placeholder,
              "input-error": !disabled && validationState === "error",
              "input-warning": !disabled && validationState === "warning",
            })}
            id={inputId}
            disabled={disabled}
          />
          {label && (
            <label
              htmlFor={inputId}
              className={classNames("input-label-base input-label-full", {
                invisible: !hasValue && props.placeholder,
                "pl-10": !!displayedIcon && iconPosition === "left",
                "input-label-small": hasValue,
              })}
            >
              {label}
            </label>
          )}
          {suffix && (
            <p className="absolute right-0 pr-3 pointer-events-none peer-disabled:text-m-grey top-[50%] translate-y-[-50%] text-m-grey">{suffix}</p>
          )}
          {displayedIcon && (
            <div
              className={classNames("input-icon-base", {
                "pr-2 right-0 mr-[3px]": iconPosition === "right",
                "pl-1 left-0 ml-[1px]": iconPosition === "left",
                "input-icon-error": validationState === "error",
                "input-icon-warning": validationState === "warning",
              })}
            >
              {displayedIcon}
            </div>
          )}
        </div>
      </div>
    );
  }
);
